<template>
  <core-view-template :title="title">
    <v-container>
      <v-row class="border">
        <v-col>
          <h4>How Does This Work?</h4>
          <p>
            Choose a Category Manager from the drop down, then download the "New Pre-Book Request"
            Template below, fill out your required fields noted with an *, then simply upload the
            Excel file.
          </p>
          <p>Required fields are marked with an <span class="red--text">*</span></p>
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <v-row
            ><v-col
              ><v-btn text color="green" class="normal-case" @click="templateClick"
                >Pre-Book Template</v-btn
              ></v-col
            ></v-row
          >
          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                label="Upload New Pre-Book Request"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ></v-file-input></v-col
            ><v-col> <v-btn @click="uploadClicked" :disabled="!file">Upload</v-btn></v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- Only show Excel processing status for an existing request -->
      <v-row v-if="!isNewRequest" class="border mt-6">
        <v-col>
          <div>
            <v-btn
              v-if="excelFileExists"
              text
              color="green"
              class="normal-case pa-0"
              @click="getPrebookExcelItems"
              >Download Excel Items</v-btn
            >
            <!-- New button to check processing status if ExcelStatus is "Processing" -->
            <v-btn
              v-if="excelStatusText === 'Processing'"
              class="ml-3"
              @click="checkProcessingStatus"
              >Check Processing Status</v-btn
            >
          </div>
          <div>
            <span style="font-size: 1.1rem"
              >Excel Items File Status:
              <strong style="font-size: 1.1rem">{{ excelStatusText }}</strong></span
            >
          </div>
        </v-col>
      </v-row>
      <!-- Only show items grid for an existing request with items -->
      <v-row v-if="!isNewRequest && itemsArrayConverted.length > 0" class="border mt-6">
        <v-col>
          <h4>Pre-Book Items</h4>
          <v-data-table
            :items="itemsArrayConverted"
            :headers="[
              { text: 'ID', value: 'id' },
              { text: 'Line', value: 'LineNumber' },
              { text: 'Vendor Item', value: 'VendorItemNumber' },
              { text: 'Shipper UPC', value: 'ShipperUPC' },
              { text: 'Description', value: 'PrebookDescription' },
              { text: 'Qty', value: 'PrebookQty' },
              { text: 'Regular Cost', value: 'PrebookRegularCost' },
              { text: 'Sale Cost', value: 'PrebookSaleCost' },
              { text: 'Regular Retail', value: 'PrebookRegularRetail' },
              { text: 'Sale Retail', value: 'PrebookSaleRetail' },
              { text: 'Sale Profit', value: 'PrebookSaleProfit' },
              { text: 'PSP Cost', value: 'PrebookPSPCost' },
              { text: 'Product UPC', value: 'ProductUPC' },
              { text: 'Product Qty', value: 'ProductQty' },
              { text: 'Off Invoice', value: 'OffInvoice' },
              { text: 'Scan Back', value: 'ScanBack' },
              { text: 'Unit Sale Cost', value: 'UnitSaleCost' },
              { text: 'Unit Sale Retail', value: 'UnitSaleRetail' },
              { text: 'Product Sale Profit', value: 'ProductSaleProfit' },
              { text: 'DC Margin', value: 'DCMargin' },
              { text: 'Month Start', value: 'MonthStart' },
              { text: 'Month End', value: 'MonthEnd' },
              { text: 'Season', value: 'Season' },
              { text: 'Year', value: 'Year' },
              { text: 'Discount Type', value: 'DiscountType' },
              { text: 'Ship Through', value: 'ShipThrough' },
              { text: 'MOQ', value: 'MOQ' },
              { text: 'PSP WNW', value: 'PspWnw' },
              { text: 'Priority Display', value: 'PriorityDisplay' },
              { text: 'Display Location', value: 'DisplayLocation' },
            ]"
            class="elevation-1"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ props.item.id }}</td>
                <td>{{ props.item.LineNumber }}</td>
                <td>{{ props.item.VendorItemNumber }}</td>
                <td>{{ props.item.ShipperUPC }}</td>
                <td>{{ props.item.PrebookDescription }}</td>
                <td>{{ props.item.PrebookQty }}</td>
                <td>{{ props.item.PrebookRegularCost }}</td>
                <td>{{ props.item.PrebookSaleCost }}</td>
                <td>{{ props.item.PrebookRegularRetail }}</td>
                <td>{{ props.item.PrebookSaleRetail }}</td>
                <td>{{ props.item.PrebookSaleProfit }}</td>
                <td>{{ props.item.PrebookPSPCost }}</td>
                <td>{{ props.item.ProductUPC }}</td>
                <td>{{ props.item.ProductQty }}</td>
                <td>{{ props.item.OffInvoice }}</td>
                <td>{{ props.item.ScanBack }}</td>
                <td>{{ props.item.UnitSaleCost }}</td>
                <td>{{ props.item.UnitSaleRetail }}</td>
                <td>{{ props.item.ProductSaleProfit }}</td>
                <td>{{ props.item.DCMargin }}</td>
                <td>{{ props.item.MonthStart }}</td>
                <td>{{ props.item.MonthEnd }}</td>
                <td>{{ props.item.Season }}</td>
                <td>{{ props.item.Year }}</td>
                <td>{{ props.item.DiscountType }}</td>
                <td>{{ props.item.ShipThrough }}</td>
                <td>{{ props.item.MOQ }}</td>
                <td>{{ props.item.PspWnw }}</td>
                <td>{{ props.item.PriorityDisplay }}</td>
                <td>{{ props.item.DisplayLocation }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <!-- New Errors Grid -->
      <v-row v-if="!isNewRequest && itemsErrorsArrayConverted.length > 0" class="border mt-6">
        <v-col>
          <h4>Item Errors</h4>
          <v-data-table
            :items="itemsErrorsArrayConverted"
            :headers="[
              { text: 'Line Number', value: 'LineNumber' },
              { text: 'Error Message', value: 'Message' },
            ]"
            class="elevation-1"
          >
            <template v-slot:item="props">
              <tr>
                <td>{{ domain.PrebookItemFunctions_getPrebookItemErrorLineText(props.item) }}</td>
                <td>
                  <!-- If the error union is PrebookItemValidationError, display its Message -->
                  {{ domain.PrebookItemFunctions_getPrebookItemErrorText(props.item) }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col cols="3">
          <v-checkbox v-model="isKit" label="Is Kit" />
        </v-col>
        <v-col>
          <div>
            <p>
              A kit is when the product (and display piece if included) all ships together in one
              container with separate UPC. The "is this a kit" box should be checked and the
              "shipper" UPC should be entered.
            </p>
            <p>
              If the display piece is empty, it needs to have a separate DAX number BEFORE the
              prebook is entered in the portal. The empty display DAX number needs to be included in
              the portal along with the actual product.
            </p>
            <p>
              If the product ships as normal (open stock), then it is NOT a kit and the box should
              NOT be checked.
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="isKit" class="border mt-6">
        <v-col>
          <span class="red--text float-left mr-3 mt-3">*</span>
          <v-text-field v-model="shipperUPC" label="Shipper UPC" />
        </v-col>
        <v-col>
          <span class="red--text float-left mr-3 mt-3">*</span>
          <v-text-field v-model="vendorPartNumber" label="Vendor Part Number" />
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <span class="red--text float-left mr-3 mt-3">*</span>
          <v-autocomplete
            v-model="categoryManager"
            :items="categoryManagers"
            :item-text="x => `${x.FirstName} ${x.LastName}`"
            :item-value="x => `${x.FirstName} ${x.LastName}`"
            autocomplete="off"
            label="Category Manager"
            clearable
          /> </v-col
      ></v-row>
      <v-row class="border mt-6">
        <v-col>
          <v-row>
            <v-col>
              <v-radio-group v-model="monthOrSeason" row>
                <v-radio label="Month" value="month"></v-radio>
                <v-radio label="Season" value="season"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-row v-if="monthOrSeason === 'month'" no-gutters>
            <v-col>
              <span class="red--text float-left mr-3 mt-3">*</span>
              <v-autocomplete
                v-model="month"
                :items="monthsSelections"
                item-text="Text"
                item-value="Value"
                autocomplete="off"
                label="Select Month"
                clearable
              />
            </v-col>
          </v-row>

          <v-row v-if="monthOrSeason === 'season'" no-gutters>
            <v-col>
              <span class="red--text float-left mr-3 mt-3">*</span>
              <v-autocomplete
                v-model="season"
                :items="seasonSelections"
                item-text="Text"
                item-value="Value"
                autocomplete="off"
                label="Select Season"
                clearable
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <span class="red--text float-left mr-3 mt-3">*</span>
          <v-autocomplete
            v-model="selectedYear"
            :items="yearSelections"
            autocomplete="off"
            label="Select Year"
            clearable
          />
        </v-col>
      </v-row>
      <v-row class="border mt-6">
        <v-col>
          <v-row>
            <v-col>
              <v-btn @click="saveClicked" :disabled="!saveEnabled" :loading="loading">{{
                saveButtonTitle
              }}</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div style="clear: both; height: 50px"></div>
    </v-container>
  </core-view-template>
</template>
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { AllStores, dispatchVendorPortal } from '@/utils/AllStores';
import { useStore } from '@/utils/CompositionApiHelpers';
import * as domain from '@psp/pogona_vendor_domain';
import { usePrebookStore } from '@/stores/prebookstore';
import { useRoute, useRouter } from 'vue-router/composables';
import * as prebookUtils from '@/utils/PrebookUtils.fs.js';
import { v4 as uuid } from 'uuid';
import * as fableUtils from '@/utils/FableUtils.fs.js';
defineComponent({
  name: 'NewPrebook',
});
const emit = defineEmits(['snackbar-success']);
const store = useStore<AllStores>();
const prebookStore = usePrebookStore();
const route = useRoute();
const router = useRouter();
const categoryManager = ref('');
const file = ref<File | null>(null);
const loading = ref(false);
const monthOrSeason = ref<'month' | 'season'>('month');
const month = ref<domain.PrebookMonthSeason_$union | null>(null);
const season = ref<domain.PrebookMonthSeason_$union | null>(null);
const newRequestId = ref(uuid());
const selectedYear = ref<number | null>(null);
const savedExcelFileName = ref('');
const excelFileExists = computed(() => {
  return fableUtils.isSome(prebookStore.prebookRequest?.ExcelPath);
});
const excelStatusText = computed(() => {
  if (fableUtils.isSome(prebookStore.prebookRequest?.ExcelStatus)) {
    return domain.PrebookRequestFunctions_getExcelProcessingStatusForDisplay(
      prebookStore.prebookRequest?.ExcelStatus as domain.ExcelProcessingStatus_$union,
    );
  } else {
    return 'Please upload the Excel items file';
  }
});
const saveButtonTitle = computed(() => (isNewRequest.value ? 'Start Pre-Book Request' : 'Save'));
const categoryManagers = computed(() => store.state.vendorportal.categoryManagers);
const currentRequestId = computed(() => {
  if (isNewRequest.value) {
    return newRequestId.value;
  } else {
    return route.params.prebookId as string;
  }
});
const uploadClicked = async () => {
  try {
    if (file.value) {
      const result = await prebookStore.uploadPrebookItems(file.value, currentRequestId.value);
      savedExcelFileName.value = result.FileName;
      // If not new request, then load request anew
      if (!isNewRequest.value) {
        await loadPrebookRequest();
      }
    }
  } catch (error) {
    console.error('Upload Error:', error);
  }
};
const title = computed(() => {
  return isNewRequest.value ? 'New Pre-Book Request' : 'Edit Pre-Book Request';
});
const isNewRequest = computed(() => route.params.prebookId === '-');
const yearSelections = computed(() => {
  return domain.PrebookRequestFunctions_yearsToDisplay(new Date().getFullYear());
});
const monthsSelections = computed(() => {
  return domain.PrebookRequestFunctions_monthsToArrayTextValue();
});
const seasonSelections = computed(() =>
  prebookStore.seasonLookup
    ? domain.SeasonLookupFunctions_getSeasonTextArray(
        prebookStore.seasonLookup as domain.SeasonLookup,
      )
    : [],
);
const saveEnabled = computed(() => {
  const basicFieldsFilled =
    categoryManager.value && (month.value || season.value) && selectedYear.value;
  const kitFieldsFilled = !isKit.value || (shipperUPC.value && vendorPartNumber.value);
  return basicFieldsFilled && kitFieldsFilled;
});
const isKit = ref(false);
const shipperUPC = ref('');
const vendorPartNumber = ref('');
const itemsArrayConverted = computed(() =>
  fableUtils.listToArray(prebookStore.prebookRequest?.Items ?? []),
);
// New computed for errors grid conversion; assumes ItemsErrors is a list
const itemsErrorsArrayConverted = computed(() =>
  fableUtils.listToArray(prebookStore.prebookRequest?.ItemErrors ?? []),
);
async function templateClick() {
  await prebookStore.getCurrentPrebookTemplate();
}
function getMonthOrSeason(): domain.PrebookMonthSeason_$union | null {
  if (monthOrSeason.value === 'month') {
    return month.value;
  } else {
    return season.value;
  }
}
function fillPrebookRequest(): domain.PrebookRequest {
  const monthOrSeason = getMonthOrSeason();
  if (monthOrSeason === null || selectedYear.value === null) {
    throw new Error('Month/Season and Year are required');
  }
  if (isNewRequest.value) {
    const newRequest = domain.PrebookRequestFunctions_initNewPrebookRequest(
      newRequestId.value,
      categoryManager.value,
      monthOrSeason,
      selectedYear.value ?? 0,
      store.state.app.username,
      isKit.value,
      isKit.value
        ? fableUtils.makeSome({
            ShipperUPC: shipperUPC.value,
            VendorPartNumber: vendorPartNumber.value,
          })
        : fableUtils.makeNone(),
    );
    return {
      ...newRequest,
      id: newRequestId.value,
      ExcelPath: fableUtils.someString(savedExcelFileName.value),
      ExcelStatus: savedExcelFileName.value
        ? fableUtils.makeSome(domain.ExcelProcessingStatus_PrebookExcelSubmitted())
        : fableUtils.makeNone(),
    } as domain.PrebookRequest;
  } else if (prebookStore.prebookRequest) {
    const prebook: domain.PrebookRequest = {
      ...prebookStore.prebookRequest,
      CategoryManager: categoryManager.value,
      MonthSeason: monthOrSeason,
      Year: selectedYear.value ?? 0,
      CreatedBy: store.state.app.username,
      KitData: isKit.value
        ? {
            ShipperUPC: shipperUPC.value,
            VendorPartNumber: vendorPartNumber.value,
          }
        : null,
      IsKit: isKit.value,
    } as domain.PrebookRequest;
    return prebook;
  } else {
    throw new Error('Prebook Request not set');
  }
}
async function saveClicked() {
  if (saveEnabled.value) {
    let prebook = fillPrebookRequest();
    // Set ExcelStatus to "ExcelProcessingInProgress" if new request and Excel file exists
    if (isNewRequest.value && savedExcelFileName.value) {
      prebook = prebookUtils.setPrebookExcelProcessing(prebook);
    }
    try {
      loading.value = true;
      await prebookStore.savePrebookRequest(prebook);
      if (isNewRequest.value) {
        prebookStore.processPrebookRequestItems(currentRequestId.value);
        emit('snackbar-success', 'Pre-Book Request Created');
        router.push({ name: 'prebook_prebookrequest', params: { prebookId: prebook.id } });
      } else {
        emit('snackbar-success', 'Pre-Book Request Updated');
        loadPrebookRequest();
      }
    } finally {
      loading.value = false;
    }
  }
}
async function loadPrebookRequest() {
  if (route.params.prebookId && route.params.prebookId !== '-') {
    try {
      loading.value = true;
      file.value = null;
      await prebookStore.getPrebookRequest(route.params.prebookId);
      categoryManager.value = prebookStore.prebookRequest?.CategoryManager ?? '';
      const mappedValue = prebookUtils.mapMonthSeasonToString(
        prebookStore.prebookRequest?.MonthSeason ?? '',
      );
      if (mappedValue === 'month' || mappedValue === 'season') {
        monthOrSeason.value = mappedValue;
      } else {
        monthOrSeason.value = 'month'; // default value or handle error
      }
      if (monthOrSeason.value === 'month') {
        month.value = prebookStore.prebookRequest?.MonthSeason as domain.PrebookMonthSeason_$union;
      } else {
        season.value = prebookStore.prebookRequest?.MonthSeason as domain.PrebookMonthSeason_$union;
      }
      selectedYear.value = prebookStore.prebookRequest?.Year ?? 0;
      isKit.value = prebookStore.prebookRequest?.IsKit ?? false;
      shipperUPC.value = domain.PrebookRequestFunctions_getPrebookKitShipperUPC(
        fableUtils.makeSome(prebookStore.prebookRequest),
      );
      vendorPartNumber.value = domain.PrebookRequestFunctions_getPrebookKitVendorPartNumber(
        fableUtils.makeSome(prebookStore.prebookRequest),
      );
    } finally {
      loading.value = false;
    }
  } else {
    file.value = null;
    categoryManager.value = '';
    monthOrSeason.value = 'month';
    month.value = null;
    season.value = null;
    selectedYear.value = null;
    isKit.value = false;
    shipperUPC.value = '';
    vendorPartNumber.value = '';
  }
}
async function getPrebookExcelItems() {
  try {
    loading.value = true;
    await prebookStore.getPrebookExcelItems(currentRequestId.value);
  } finally {
    loading.value = false;
  }
}
// Add new function to check processing status
async function checkProcessingStatus() {
  try {
    loading.value = true;
    await loadPrebookRequest();
  } finally {
    loading.value = false;
  }
}
watch(() => route.params.prebookId, loadPrebookRequest);
onMounted(async () => {
  try {
    loading.value = true;
    await dispatchVendorPortal(store, 'getCategoryManagers', null);
    await loadPrebookRequest();
    await prebookStore.getSeasonLookup();
  } finally {
    loading.value = false;
  }
});
</script>
<style scoped>
.border {
  border: 1px solid #000; /* or any other color */
  border-radius: 4px; /* optional */
  /* Make border color light gray color */
  border-color: #d3d3d3;
}
.normal-case {
  text-transform: none;
}
</style>
