import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import { Ripple } from 'vuetify/lib/directives';
import theme from './theme';

Vue.use(Vuetify);

// Initialize using localStorage value (defaults to false)
const isDark = localStorage.getItem('darkMode') === 'true';

const vuetify = new Vuetify({
  iconfont: 'md',
  theme: {
    ...theme,
    dark: isDark,
  },
  directives: {
    Ripple,
  },
});

export default vuetify;
