import { Union } from "../../fable-library-js.4.24.0/Types.js";
import { option_type, lambda_type, class_type, unit_type, union_type, list_type, string_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookItem_$reflection, PrebookItemError_$reflection } from "./PrebookItem.fs.js";
import { PrebookRequest, PrebookRequest_$reflection } from "./PrebookRequest.fs.js";
import { FSharpResult$2 } from "../../fable-library-js.4.24.0/Result.js";
import { singleton } from "../../fable-library-js.4.24.0/AsyncBuilder.js";
import { empty, filter, append, cons } from "../../fable-library-js.4.24.0/List.js";

export class PrebookCommandResult extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["PrebookSuccess", "PrebookError", "ValidateItemResult", "PrebookResult"];
    }
}

export function PrebookCommandResult_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookCommandResult", [], PrebookCommandResult, () => [[], [["message", string_type]], [["errors", list_type(PrebookItemError_$reflection())]], [["request", PrebookRequest_$reflection()]]]);
}

export class PrebookCommand extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UpsertPrebook", "ValidateItem", "AddItemToPrebookRequest", "RemoveItem", "AddItemError", "ClearItems", "ClearItemErrors"];
    }
}

export function PrebookCommand_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookCommand", [], PrebookCommand, () => [[["request", PrebookRequest_$reflection()], ["upsertFun", option_type(lambda_type(PrebookRequest_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, string_type], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", string_type]]])])))]], [["item", PrebookItem_$reflection()]], [["request", PrebookRequest_$reflection()], ["item", PrebookItem_$reflection()]], [["request", PrebookRequest_$reflection()], ["itemId", class_type("System.Guid")]], [["request", PrebookRequest_$reflection()], ["error", PrebookItemError_$reflection()]], [["request", PrebookRequest_$reflection()]], [["request", PrebookRequest_$reflection()]]]);
}

export function PrebookCommandFunctions_upsertPrebook(request, upsertFun) {
    return singleton.Delay(() => singleton.Bind(upsertFun(request), (_arg) => ((_arg.tag === 1) ? singleton.Return(new PrebookCommandResult(1, [_arg.fields[0]])) : singleton.Return(new PrebookCommandResult(0, [])))));
}

export function PrebookCommandFunctions_validateItem(item) {
    return singleton.Delay(() => singleton.Return(new PrebookCommandResult(0, [])));
}

export function PrebookCommandFunctions_addItemError(request, error) {
    return singleton.Delay(() => singleton.Return(new PrebookCommandResult(3, [new PrebookRequest(request.id, request.CategoryManager, request.MonthSeason, request.Year, request.CreatedBy, request.CreatedDate, request.PrebookErrors, request.Items, cons(error, request.ItemErrors), request.ExcelPath, request.ExcelStatus, request.Status, request.IsKit, request.KitData)])));
}

export function PrebookCommandFunctions_addItemToPrebookRequest(request, item) {
    return singleton.Delay(() => singleton.Bind(PrebookCommandFunctions_validateItem(item), (_arg) => {
        const validateResult = _arg;
        const requestWithErrors = (validateResult.tag === 2) ? (new PrebookRequest(request.id, request.CategoryManager, request.MonthSeason, request.Year, request.CreatedBy, request.CreatedDate, request.PrebookErrors, request.Items, append(validateResult.fields[0], request.ItemErrors), request.ExcelPath, request.ExcelStatus, request.Status, request.IsKit, request.KitData)) : request;
        const updatedRequest = new PrebookRequest(requestWithErrors.id, requestWithErrors.CategoryManager, requestWithErrors.MonthSeason, requestWithErrors.Year, requestWithErrors.CreatedBy, requestWithErrors.CreatedDate, requestWithErrors.PrebookErrors, cons(item, requestWithErrors.Items), requestWithErrors.ItemErrors, requestWithErrors.ExcelPath, requestWithErrors.ExcelStatus, requestWithErrors.Status, requestWithErrors.IsKit, requestWithErrors.KitData);
        return singleton.Return(new PrebookCommandResult(3, [updatedRequest]));
    }));
}

export function PrebookCommandFunctions_removeItem(request, itemId) {
    return singleton.Delay(() => {
        const updatedRequest = new PrebookRequest(request.id, request.CategoryManager, request.MonthSeason, request.Year, request.CreatedBy, request.CreatedDate, request.PrebookErrors, filter((item) => (item.id !== itemId), request.Items), request.ItemErrors, request.ExcelPath, request.ExcelStatus, request.Status, request.IsKit, request.KitData);
        return singleton.Return(new PrebookCommandResult(3, [updatedRequest]));
    });
}

export function PrebookCommandFunctions_clearItems(request) {
    return singleton.Delay(() => {
        const updatedRequest = new PrebookRequest(request.id, request.CategoryManager, request.MonthSeason, request.Year, request.CreatedBy, request.CreatedDate, request.PrebookErrors, empty(), request.ItemErrors, request.ExcelPath, request.ExcelStatus, request.Status, request.IsKit, request.KitData);
        return singleton.Return(new PrebookCommandResult(3, [updatedRequest]));
    });
}

export function PrebookCommandFunctions_clearItemErrors(request) {
    return singleton.Delay(() => {
        const updatedRequest = new PrebookRequest(request.id, request.CategoryManager, request.MonthSeason, request.Year, request.CreatedBy, request.CreatedDate, request.PrebookErrors, request.Items, empty(), request.ExcelPath, request.ExcelStatus, request.Status, request.IsKit, request.KitData);
        return singleton.Return(new PrebookCommandResult(3, [updatedRequest]));
    });
}

export function PrebookCommandFunctions_processCommand(upsertFun, command) {
    switch (command.tag) {
        case 1:
            return PrebookCommandFunctions_validateItem(command.fields[0]);
        case 2:
            return PrebookCommandFunctions_addItemToPrebookRequest(command.fields[0], command.fields[1]);
        case 3:
            return PrebookCommandFunctions_removeItem(command.fields[0], command.fields[1]);
        case 4:
            return PrebookCommandFunctions_addItemError(command.fields[0], command.fields[1]);
        case 5:
            return PrebookCommandFunctions_clearItems(command.fields[0]);
        case 6:
            return PrebookCommandFunctions_clearItemErrors(command.fields[0]);
        default:
            if (command.fields[1] == null) {
                if (upsertFun == null) {
                    return singleton.Delay(() => singleton.Return(new PrebookCommandResult(1, ["No upsert function provided"])));
                }
                else {
                    return PrebookCommandFunctions_upsertPrebook(command.fields[0], upsertFun);
                }
            }
            else {
                const upsertFun_1 = command.fields[1];
                return PrebookCommandFunctions_upsertPrebook(command.fields[0], upsertFun_1);
            }
    }
}

