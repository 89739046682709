import { PrebookRequest, ExcelProcessingStatus } from "../fable_modules/pogona_vendor_domain.fable.1.602.0/prebook/PrebookRequest.fs.js";

export function mapMonthSeasonToString(monthSeason) {
    if (monthSeason.tag === 1) {
        return "season";
    }
    else {
        return "month";
    }
}

export function setPrebookExcelProcessing(prebookRequest) {
    return new PrebookRequest(prebookRequest.id, prebookRequest.CategoryManager, prebookRequest.MonthSeason, prebookRequest.Year, prebookRequest.CreatedBy, prebookRequest.CreatedDate, prebookRequest.PrebookErrors, prebookRequest.Items, prebookRequest.ItemErrors, prebookRequest.ExcelPath, new ExcelProcessingStatus(1, []), prebookRequest.Status, prebookRequest.IsKit, prebookRequest.KitData);
}

