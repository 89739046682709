import { Remoting_buildProxy_64DC51C } from "../fable_modules/Fable.Remoting.Client.7.32.0/./Remoting.fs.js";
import { RemotingModule_withAuthorizationHeader, RemotingModule_createApi, RemotingModule_withBaseUrl, RemotingModule_withRouteBuilder } from "../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { routeBuilder } from "./ApiShared.fs.js";
import { printf, toText } from "../fable_modules/fable-library-js.4.24.0/String.js";
import { IFinanceApi_$reflection } from "../fable_modules/pogona_vendor_api_lib.fable.1.602.0/IFinanceApi.fs.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { startAsPromise } from "../fable_modules/fable-library-js.4.24.0/Async.js";
import { ofArray, toArray } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { some } from "../fable_modules/fable-library-js.4.24.0/Option.js";
import { Record } from "../fable_modules/fable-library-js.4.24.0/Types.js";
import { CustTransComputedDto_$reflection, CustTransBiEntityDto_$reflection, CustSettlementBiEntityDto_$reflection, VendTransComputedDto_$reflection, VendTransBiEntityDto_$reflection, VendSettlementBiEntityDto_$reflection } from "../fable_modules/pogona_vendor_api_lib.fable.1.602.0/D365Types.fs.js";
import { record_type, array_type } from "../fable_modules/fable-library-js.4.24.0/Reflection.js";

export function createFinanceApiClient(baseUrl, authToken) {
    let options_2;
    return Remoting_buildProxy_64DC51C((options_2 = RemotingModule_withRouteBuilder(routeBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi())), RemotingModule_withAuthorizationHeader(toText(printf("Bearer %s"))(authToken), options_2)), IFinanceApi_$reflection());
}

export function financeApiClient(baseUrl, getToken) {
    return PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
        let pr;
        return ((pr = getToken(), pr.then((token) => createFinanceApiClient(baseUrl, token))));
    }));
}

export function callGetSalesInvoiceHeaders(skip, take, client) {
    const pr = startAsPromise(client.getSalesInvoiceHeaders(skip, take));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting sales invoice headers: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    });
}

export function callGetSalesInvoice(invoiceNumber, client) {
    const pr = startAsPromise(client.getSalesInvoice(invoiceNumber));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting sales invoice: ${result.fields[0]}`);
        }
        else if (result.fields[0] == null) {
            return undefined;
        }
        else {
            const invoiceData = result.fields[0][3];
            return [result.fields[0][0], result.fields[0][1], toArray(result.fields[0][2]), invoiceData];
        }
    });
}

export function callSearchInvoices(parameters, client) {
    const pr = startAsPromise(client.searchInvoices(parameters));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching invoices: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    });
}

export function callSearchVendors(search, dataAreaIds, skip, take, client) {
    const pr = startAsPromise(client.searchVendors(search, ofArray(dataAreaIds), skip, take));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching vendors: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    });
}

export function callSearchCustomers(search, dataAreaIds, skip, take, client) {
    const pr = startAsPromise(client.searchCustomers(search, ofArray(dataAreaIds), skip, take));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching customers: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    });
}

export function getLegalEntities(client) {
    const pr = startAsPromise(client.getLegalEntities);
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting legal entities: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    });
}

export function callImpersonateD365User(userId, client) {
    const pr = startAsPromise(client.impersonateD365User(userId));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error impersonating D365 user: ${result.fields[0]}`);
        }
    });
}

export function callGetD365UserImpersonation(client) {
    const pr = startAsPromise(client.getD365UserImpersonation);
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting D365 user impersonation: ${result.fields[0]}`);
        }
        else {
            return result.fields[0];
        }
    });
}

export function callStopImpersonatingD365User(client) {
    const pr = startAsPromise(client.stopImpersonatingD365User);
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error stopping impersonating D365 user: ${result.fields[0]}`);
        }
    });
}

export function callGetUserData(userId, client) {
    const pr = startAsPromise(client.getUserData(userId));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting user data: ${result.fields[0]}`);
        }
        else {
            return result.fields[0];
        }
    });
}

export function callRemoveUserAccount(userName, account, accountType, client) {
    const pr = startAsPromise(client.removeUserAccount(userName, account, accountType));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error removing user account: ${result.fields[0]}`);
        }
    });
}

export function callDeleteUserCacheEntry(userName, client) {
    const pr = startAsPromise(client.deleteUserCacheEntry(userName));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error deleting user cache entry: ${result.fields[0]}`);
        }
    });
}

export function callSearchPurchaseOrderHeaders(parameters, client) {
    const pr = startAsPromise(client.searchPurchaseOrderHeaders(parameters));
    return pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching purchase order headers: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    });
}

export function callGetPOByNumber(poNumber, client) {
    let pr_1;
    const pr = startAsPromise(client.getPOByNumber(poNumber));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting purchase order: ${result.fields[0]}`);
        }
        else {
            return result.fields[0];
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callSearchVendorsWithRestriction(search, skip, take, client) {
    let pr_1;
    const pr = startAsPromise(client.searchVendorsWithRestriction(search, skip, take));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching vendors with restriction: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callSearchVendTransactions(parameters, client) {
    let pr_1;
    const pr = startAsPromise(client.searchVendTransactions(parameters));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching vendor transactions: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callSearchCustTransactions(parameters, client) {
    let pr_1;
    const pr = startAsPromise(client.searchCustTransactions(parameters));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching customer transactions: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callSearchCustomersWithRestriction(search, skip, take, client) {
    let pr_1;
    const pr = startAsPromise(client.searchCustomersWithRestriction(search, skip, take));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error searching customers with restriction: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callGetCustBalance(custAccount, client) {
    let pr_1;
    const pr = startAsPromise(client.getCustBalance(custAccount));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting customer balance: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callGetVendBalance(vendAccount, client) {
    let pr_1;
    const pr = startAsPromise(client.getVendBalance(vendAccount));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting vendor balance: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export class VendSettlementResultJS extends Record {
    constructor(Settlements, Transactions, Computed) {
        super();
        this.Settlements = Settlements;
        this.Transactions = Transactions;
        this.Computed = Computed;
    }
}

export function VendSettlementResultJS_$reflection() {
    return record_type("FinanceApiClient.VendSettlementResultJS", [], VendSettlementResultJS, () => [["Settlements", array_type(VendSettlementBiEntityDto_$reflection())], ["Transactions", array_type(VendTransBiEntityDto_$reflection())], ["Computed", array_type(VendTransComputedDto_$reflection())]]);
}

export function callGetVendSettlementsForTrans(voucher, dataAreadId, client) {
    let pr_1;
    const pr = startAsPromise(client.getVendSettlementsForTrans(voucher, dataAreadId));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting vendor settlements for transaction: ${result.fields[0]}`);
        }
        else {
            const result_1 = result.fields[0];
            return new VendSettlementResultJS(toArray(result_1.Settlements), toArray(result_1.Transactions), toArray(result_1.Computed));
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export class CustSettlementResultJS extends Record {
    constructor(Settlements, Transactions, Computed) {
        super();
        this.Settlements = Settlements;
        this.Transactions = Transactions;
        this.Computed = Computed;
    }
}

export function CustSettlementResultJS_$reflection() {
    return record_type("FinanceApiClient.CustSettlementResultJS", [], CustSettlementResultJS, () => [["Settlements", array_type(CustSettlementBiEntityDto_$reflection())], ["Transactions", array_type(CustTransBiEntityDto_$reflection())], ["Computed", array_type(CustTransComputedDto_$reflection())]]);
}

export function callGetCustSettlementsForTrans(voucher, dataAreadId, client) {
    let pr_1;
    const pr = startAsPromise(client.getCustSettlementsForTrans(voucher, dataAreadId));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting customer settlements for transaction: ${result.fields[0]}`);
        }
        else {
            const result_1 = result.fields[0];
            return new CustSettlementResultJS(toArray(result_1.Settlements), toArray(result_1.Transactions), toArray(result_1.Computed));
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

export function callGetPODCDocuments(poNumber, client) {
    let pr_1;
    const pr = startAsPromise(client.getPODCDocuments(poNumber));
    pr_1 = (pr.then((result) => {
        if (result.tag === 1) {
            throw new Error(`Error getting PODC documents: ${result.fields[0]}`);
        }
        else {
            return toArray(result.fields[0]);
        }
    }));
    return pr_1.catch((ex) => {
        console.error(some(ex.message));
        throw ex;
    });
}

