<template>
  <v-btn @click="toggleTheme" small> {{ isDark ? 'Light' : 'Dark' }} Mode </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';

export default Vue.extend({
  name: 'DarkModeToggle',
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem('darkMode', String(this.$vuetify.theme.dark));
    },
  },
});
</script>

<!-- ...styles if needed... -->
