import { initBatch as initBatch_1 } from "../fable_modules/pogona_vendor_domain.fable.1.602.0/Batch.fs.js";
import { computeGrossPercent as computeGrossPercent_1, computeStoreCostCase as computeStoreCostCase_1 } from "../fable_modules/pogona_vendor_domain.fable.1.602.0/VendorDomain.fs.js";
import { fromParts } from "../fable_modules/fable-library-js.4.24.0/Decimal.js";
import Decimal from "../fable_modules/fable-library-js.4.24.0/Decimal.js";
import { map, updateAt, removeAt, singleton, append, empty } from "../fable_modules/fable-library-js.4.24.0/List.js";
import { Distributor, UnitOrCase, DsdDistributorData } from "../fable_modules/pogona_vendor_domain.fable.1.602.0/NewItemRequest.fs.js";

export const initBatch = initBatch_1;

export function computeStoreCostCase(masterCase, shipUnitCostFromPspd) {
    return computeStoreCostCase_1(masterCase, shipUnitCostFromPspd);
}

export function computeGrossPercent(shipUnitCostFromPspd, retailBasePrice) {
    return computeGrossPercent_1(new Decimal(shipUnitCostFromPspd), new Decimal(retailBasePrice));
}

export function initDsdDistributorData() {
    return new DsdDistributorData("", "", "", empty());
}

export function addEmptyDistributor(distributor) {
    return new DsdDistributorData(distributor.DaxItemNumber, distributor.Upc, distributor.ItemDescription, append(distributor.Distributors, singleton(new Distributor("", "", fromParts(0, 0, 0, false, 0), new UnitOrCase(0, [])))));
}

export function removeDistributor(index, dsdDistributor) {
    return new DsdDistributorData(dsdDistributor.DaxItemNumber, dsdDistributor.Upc, dsdDistributor.ItemDescription, removeAt(index, dsdDistributor.Distributors));
}

export function updateDistributor(index, distributor, dsdDistributor) {
    return new DsdDistributorData(dsdDistributor.DaxItemNumber, dsdDistributor.Upc, dsdDistributor.ItemDescription, updateAt(index, distributor, dsdDistributor.Distributors));
}

export function updateDistributorShipToStoreAs(unitOrCase, distributor) {
    return new Distributor(distributor.DistributorId, distributor.ExternalItemId, distributor.StoreCost, (unitOrCase === "Unit") ? (new UnitOrCase(0, [])) : ((unitOrCase === "Case") ? (new UnitOrCase(1, [])) : (() => {
        throw new Error(`Could not map unitOrCase ${unitOrCase}`);
    })()));
}

export function getDsdDistributor(request) {
    const matchValue = request.Distributors;
    if (matchValue != null) {
        const d = matchValue;
        return new DsdDistributorData(d.DaxItemNumber, d.Upc, d.ItemDescription, map((d_1) => (new Distributor(d_1.DistributorId, d_1.ExternalItemId, d_1.StoreCost, d_1.ShipToStoreAs)), d.Distributors));
    }
    else {
        return initDsdDistributorData();
    }
}

