import { FSharpRef, Union, Record } from "../../fable-library-js.4.24.0/Types.js";
import { union_type, decimal_type, option_type, class_type, record_type, string_type, int32_type } from "../../fable-library-js.4.24.0/Reflection.js";
import { PrebookItemD365Data_$reflection } from "./PrebookItemD365Data.fs.js";
import { isNullOrWhiteSpace, printf, toText } from "../../fable-library-js.4.24.0/String.js";
import { ofArray, forAll, append, isEmpty, empty, cons } from "../../fable-library-js.4.24.0/List.js";
import { FSharpResult$2, Result_MapError } from "../../fable-library-js.4.24.0/Result.js";
import { validateUPC } from "../VendorDomain.fs.js";
import { parse, tryParse } from "../../fable-library-js.4.24.0/Int32.js";
import { fromParts, parse as parse_1, tryParse as tryParse_1 } from "../../fable-library-js.4.24.0/Decimal.js";
import Decimal from "../../fable-library-js.4.24.0/Decimal.js";
import { int32ToString } from "../../fable-library-js.4.24.0/Util.js";

export class PrebookRequestForm extends Record {
    constructor(LineNumber, FieldName, VendorItemNumber, ShipperUPC, PrebookDescription, ProductUPC, ProductQty, OffInvoice, ScanBack, ShipThrough, MOQ) {
        super();
        this.LineNumber = (LineNumber | 0);
        this.FieldName = FieldName;
        this.VendorItemNumber = VendorItemNumber;
        this.ShipperUPC = ShipperUPC;
        this.PrebookDescription = PrebookDescription;
        this.ProductUPC = ProductUPC;
        this.ProductQty = ProductQty;
        this.OffInvoice = OffInvoice;
        this.ScanBack = ScanBack;
        this.ShipThrough = ShipThrough;
        this.MOQ = MOQ;
    }
}

export function PrebookRequestForm_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookRequestForm", [], PrebookRequestForm, () => [["LineNumber", int32_type], ["FieldName", string_type], ["VendorItemNumber", string_type], ["ShipperUPC", string_type], ["PrebookDescription", string_type], ["ProductUPC", string_type], ["ProductQty", string_type], ["OffInvoice", string_type], ["ScanBack", string_type], ["ShipThrough", string_type], ["MOQ", string_type]]);
}

export class PrebookItem extends Record {
    constructor(id, LineNumber, D365Data, VendorItemNumber, ShipperUPC, PrebookDescription, PrebookQty, PrebookRegularCost, PrebookSaleCost, PrebookRegularRetail, PrebookSaleRetail, PrebookSaleProfit, PrebookPSPCost, ProductUPC, ProductQty, OffInvoice, ScanBack, UnitSaleCost, UnitSaleRetail, ProductSaleProfit, DCMargin, MonthStart, MonthEnd, Season, Year, DiscountType, ShipThrough, MOQ, PspWnw, PriorityDisplay, DisplayLocation) {
        super();
        this.id = id;
        this.LineNumber = (LineNumber | 0);
        this.D365Data = D365Data;
        this.VendorItemNumber = VendorItemNumber;
        this.ShipperUPC = ShipperUPC;
        this.PrebookDescription = PrebookDescription;
        this.PrebookQty = (PrebookQty | 0);
        this.PrebookRegularCost = PrebookRegularCost;
        this.PrebookSaleCost = PrebookSaleCost;
        this.PrebookRegularRetail = PrebookRegularRetail;
        this.PrebookSaleRetail = PrebookSaleRetail;
        this.PrebookSaleProfit = PrebookSaleProfit;
        this.PrebookPSPCost = PrebookPSPCost;
        this.ProductUPC = ProductUPC;
        this.ProductQty = (ProductQty | 0);
        this.OffInvoice = OffInvoice;
        this.ScanBack = ScanBack;
        this.UnitSaleCost = UnitSaleCost;
        this.UnitSaleRetail = UnitSaleRetail;
        this.ProductSaleProfit = ProductSaleProfit;
        this.DCMargin = DCMargin;
        this.MonthStart = MonthStart;
        this.MonthEnd = MonthEnd;
        this.Season = Season;
        this.Year = (Year | 0);
        this.DiscountType = DiscountType;
        this.ShipThrough = ShipThrough;
        this.MOQ = MOQ;
        this.PspWnw = PspWnw;
        this.PriorityDisplay = PriorityDisplay;
        this.DisplayLocation = DisplayLocation;
    }
}

export function PrebookItem_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookItem", [], PrebookItem, () => [["id", class_type("System.Guid")], ["LineNumber", int32_type], ["D365Data", option_type(PrebookItemD365Data_$reflection())], ["VendorItemNumber", string_type], ["ShipperUPC", string_type], ["PrebookDescription", string_type], ["PrebookQty", int32_type], ["PrebookRegularCost", decimal_type], ["PrebookSaleCost", decimal_type], ["PrebookRegularRetail", decimal_type], ["PrebookSaleRetail", decimal_type], ["PrebookSaleProfit", decimal_type], ["PrebookPSPCost", decimal_type], ["ProductUPC", string_type], ["ProductQty", int32_type], ["OffInvoice", decimal_type], ["ScanBack", decimal_type], ["UnitSaleCost", decimal_type], ["UnitSaleRetail", decimal_type], ["ProductSaleProfit", decimal_type], ["DCMargin", decimal_type], ["MonthStart", string_type], ["MonthEnd", string_type], ["Season", string_type], ["Year", int32_type], ["DiscountType", string_type], ["ShipThrough", string_type], ["MOQ", string_type], ["PspWnw", string_type], ["PriorityDisplay", string_type], ["DisplayLocation", string_type]]);
}

export class PrebookValidationError extends Record {
    constructor(LineNumber, Message) {
        super();
        this.LineNumber = (LineNumber | 0);
        this.Message = Message;
    }
}

export function PrebookValidationError_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookValidationError", [], PrebookValidationError, () => [["LineNumber", int32_type], ["Message", string_type]]);
}

export class PrebookItemErrorType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["TestError", "PrebookItemValidationError"];
    }
}

export function PrebookItemErrorType_$reflection() {
    return union_type("pogona_vendor_domain.prebook.PrebookItemErrorType", [], PrebookItemErrorType, () => [[], [["error", PrebookValidationError_$reflection()]]]);
}

export class PrebookItemError extends Record {
    constructor(PrebookItemId, Error$) {
        super();
        this.PrebookItemId = PrebookItemId;
        this.Error = Error$;
    }
}

export function PrebookItemError_$reflection() {
    return record_type("pogona_vendor_domain.prebook.PrebookItemError", [], PrebookItemError, () => [["PrebookItemId", class_type("System.Guid")], ["Error", PrebookItemErrorType_$reflection()]]);
}

export function PrebookItemFunctions_initEmptyPrebookRequestForm(lineNumber) {
    return new PrebookRequestForm(lineNumber, "", "", "", "", "", "", "", "", "", "");
}

export function PrebookItemFunctions_initVendorPrebookFormItem(lineNumber, vendorItemNumber, shipperUPC, prebookDescription, productUPC, productQty, offInvoice, scanBack, shipThrough, moq) {
    const baseForm = PrebookItemFunctions_initEmptyPrebookRequestForm(lineNumber);
    return new PrebookRequestForm(baseForm.LineNumber, baseForm.FieldName, vendorItemNumber, shipperUPC, prebookDescription, productUPC, productQty, offInvoice, scanBack, shipThrough, moq);
}

export function PrebookItemFunctions_validateField(parsed, errors, lineNumber, message, value) {
    if (!parsed) {
        return cons(new PrebookValidationError(lineNumber, toText(printf("%s: %s"))(message)(value)), errors);
    }
    else {
        return errors;
    }
}

export function PrebookItemFunctions_validateRequiredField(field, errors, lineNumber, message) {
    if (isNullOrWhiteSpace(field)) {
        return cons(new PrebookValidationError(lineNumber, message), errors);
    }
    else {
        return errors;
    }
}

export function PrebookItemFunctions_validateUPCField(upc, fieldName, errors, lineNumber) {
    const _arg = Result_MapError((e) => (new PrebookValidationError(lineNumber, (e.tag === 2) ? toText(printf("%s Invalid UPC Check Digit"))(fieldName) : ((e.tag === 0) ? "Valid UPC" : toText(printf("%s Invalid UPC Length: %d"))(fieldName)(e.fields[0])))), validateUPC(upc));
    if (_arg.tag === 1) {
        return cons(_arg.fields[0], errors);
    }
    else {
        return errors;
    }
}

export function PrebookItemFunctions_checkUPC(upcRepo, upc) {
    if (upcRepo(upc)) {
        return new FSharpResult$2(0, [undefined]);
    }
    else {
        return new FSharpResult$2(1, ["UPC not found"]);
    }
}

export function PrebookItemFunctions_checkUPCField(upcRepo, upc, fieldName, errors, lineNumber) {
    const _arg = Result_MapError((e) => (new PrebookValidationError(lineNumber, toText(printf("%s: %s"))(fieldName)(e))), PrebookItemFunctions_checkUPC(upcRepo, upc));
    if (_arg.tag === 1) {
        return cons(_arg.fields[0], errors);
    }
    else {
        return errors;
    }
}

export function PrebookItemFunctions_requiredPetPartnerFormFields(form, isKit) {
    const errors_6 = PrebookItemFunctions_validateRequiredField(form.ShipThrough, PrebookItemFunctions_validateRequiredField(form.ScanBack, PrebookItemFunctions_validateRequiredField(form.OffInvoice, PrebookItemFunctions_validateRequiredField(form.ProductQty, PrebookItemFunctions_validateRequiredField(form.ProductUPC, PrebookItemFunctions_validateRequiredField(form.PrebookDescription, empty(), form.LineNumber, "Prebook Description is required"), form.LineNumber, "Product UPC is required"), form.LineNumber, "Product Qty is required"), form.LineNumber, "Off Invoice is required"), form.LineNumber, "Scan Back is required"), form.LineNumber, "Ship Through is required");
    if (isKit) {
        return PrebookItemFunctions_validateRequiredField(form.ShipperUPC, PrebookItemFunctions_validateRequiredField(form.VendorItemNumber, errors_6, form.LineNumber, "Vendor Item # is required for Kit"), form.LineNumber, "Shipper UPC is required for Kit");
    }
    else {
        return errors_6;
    }
}

export function PrebookItemFunctions_validatePetPartnerFormUPCs(upcRepo, form) {
    return PrebookItemFunctions_checkUPCField(upcRepo, form.ShipperUPC, "ShipperUPC", PrebookItemFunctions_checkUPCField(upcRepo, form.ProductUPC, "ProductUPC", PrebookItemFunctions_validateUPCField(form.ShipperUPC, "ShipperUPC", PrebookItemFunctions_validateUPCField(form.ProductUPC, "ProductUPC", empty(), form.LineNumber), form.LineNumber), form.LineNumber), form.LineNumber);
}

export function PrebookItemFunctions_validatePrebookFormNumbers(form) {
    let outArg, outArg_1, outArg_2;
    const errors_1 = PrebookItemFunctions_validateField(((outArg = 0, [tryParse(form.ProductQty, 511, false, 32, new FSharpRef(() => outArg, (v) => {
        outArg = (v | 0);
    })), outArg]))[0], empty(), form.LineNumber, "Invalid PrebookQty", form.ProductQty);
    const errors_2 = PrebookItemFunctions_validateField(((outArg_1 = (new Decimal("0")), [tryParse_1(form.OffInvoice, new FSharpRef(() => outArg_1, (v_1) => {
        outArg_1 = v_1;
    })), outArg_1]))[0], errors_1, form.LineNumber, "Invalid OffInvoice", form.OffInvoice);
    return PrebookItemFunctions_validateField(((outArg_2 = (new Decimal("0")), [tryParse_1(form.ScanBack, new FSharpRef(() => outArg_2, (v_2) => {
        outArg_2 = v_2;
    })), outArg_2]))[0], errors_2, form.LineNumber, "Invalid ScanBack", form.ScanBack);
}

export function PrebookItemFunctions_copyFormToItem(form, id) {
    const prebookQty = parse(form.ProductQty, 511, false, 32) | 0;
    const offInvoice = parse_1(form.OffInvoice);
    const scanBack = parse_1(form.ScanBack);
    return new PrebookItem(id, form.LineNumber, undefined, form.VendorItemNumber, form.ShipperUPC, form.PrebookDescription, prebookQty, fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), form.ProductUPC, 0, offInvoice, scanBack, fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), "", "", "", 0, "", form.ShipThrough, form.MOQ, "", "", "");
}

export function PrebookItemFunctions_validatePetPartnerReqeustFormItem(upcRepo, form, isKit) {
    const requiredErrors = PrebookItemFunctions_requiredPetPartnerFormFields(form, isKit);
    if (isEmpty(requiredErrors)) {
        return append(PrebookItemFunctions_validatePetPartnerFormUPCs(upcRepo, form), PrebookItemFunctions_validatePrebookFormNumbers(form));
    }
    else {
        return requiredErrors;
    }
}

export function PrebookItemFunctions_checkForEmptyPrebookRequestForm(form) {
    return forAll(isNullOrWhiteSpace, ofArray([form.FieldName, form.VendorItemNumber, form.ShipperUPC, form.PrebookDescription, form.ProductQty, form.OffInvoice, form.ScanBack, form.ShipThrough, form.MOQ]));
}

export function PrebookItemFunctions_getPrebookItemErrorLineText(error) {
    const matchValue = error.Error;
    if (matchValue.tag === 1) {
        return int32ToString(matchValue.fields[0].LineNumber);
    }
    else {
        return "";
    }
}

export function PrebookItemFunctions_getPrebookItemErrorText(error) {
    const matchValue = error.Error;
    if (matchValue.tag === 1) {
        return matchValue.fields[0].Message;
    }
    else {
        return "Test Error";
    }
}

